import { _HasDataLoaderMeta } from 'unplugin-vue-router/runtime'

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/code/gunauction/gunauction-front/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/code/gunauction/gunauction-front/src/pages/[...all].vue'),
    /* no children */
  },
  {
    path: '/app',
    /* internal name: '/app' */
    /* no component */
    children: [
      {
        path: 'buyers-protection',
        name: '/app/buyers-protection',
        component: () => import('/code/gunauction/gunauction-front/src/pages/app/buyers-protection.vue'),
        /* no children */
      },
      {
        path: 'gun-management-system',
        name: '/app/gun-management-system',
        component: () => import('/code/gunauction/gunauction-front/src/pages/app/gun-management-system.vue'),
        /* no children */
      },
      {
        path: 'licencing',
        name: '/app/licencing',
        component: () => import('/code/gunauction/gunauction-front/src/pages/app/licencing.vue'),
        /* no children */
      },
      {
        path: 'premium',
        name: '/app/premium',
        component: () => import('/code/gunauction/gunauction-front/src/pages/app/premium.vue'),
        /* no children */
      },
      {
        path: 'privacy',
        name: '/app/privacy',
        component: () => import('/code/gunauction/gunauction-front/src/pages/app/privacy.vue'),
        /* no children */
      },
      {
        path: 'register-interest',
        name: '/app/register-interest',
        component: () => import('/code/gunauction/gunauction-front/src/pages/app/register-interest.vue'),
        /* no children */
      },
      {
        path: 'terms',
        name: '/app/terms',
        component: () => import('/code/gunauction/gunauction-front/src/pages/app/terms.vue'),
        /* no children */
      },
      {
        path: 'user-agreement',
        name: '/app/user-agreement',
        component: () => import('/code/gunauction/gunauction-front/src/pages/app/user-agreement.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/auctions',
    name: '/auctions',
    component: () => import('/code/gunauction/gunauction-front/src/pages/auctions.vue'),
    children: [
      {
        path: '',
        name: '/auctions/',
        component: () => import('/code/gunauction/gunauction-front/src/pages/auctions/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/auctions/[id]',
        component: () => import('/code/gunauction/gunauction-front/src/pages/auctions/[id].vue'),
        /* no children */
      },
      {
        path: 'invoice',
        /* internal name: '/auctions/invoice' */
        /* no component */
        children: [
          {
            path: ':id',
            name: '/auctions/invoice/[id]',
            component: () => import('/code/gunauction/gunauction-front/src/pages/auctions/invoice/[id].vue'),
            /* no children */
          }
        ],
      }
    ],
    meta: {
      "requiresAuth": false
    }
  },
  {
    path: '/auth',
    name: '/auth',
    component: () => import('/code/gunauction/gunauction-front/src/pages/auth.vue'),
    children: [
      {
        path: 'login',
        name: '/auth/login',
        component: () => import('/code/gunauction/gunauction-front/src/pages/auth/login.vue'),
        /* no children */
      },
      {
        path: 'welcome',
        name: '/auth/welcome',
        component: () => import('/code/gunauction/gunauction-front/src/pages/auth/welcome.vue'),
        /* no children */
        meta: {
          "requiresAuth": true
        }
      }
    ],
  },
  {
    path: '/settings',
    name: '/settings',
    component: () => import('/code/gunauction/gunauction-front/src/pages/settings.vue'),
    children: [
      {
        path: 'profile',
        name: '/settings/profile',
        component: () => import('/code/gunauction/gunauction-front/src/pages/settings/profile.vue'),
        children: [
          {
            path: '',
            name: '/settings/profile/',
            component: () => import('/code/gunauction/gunauction-front/src/pages/settings/profile/index.vue'),
            /* no children */
          },
          {
            path: 'approvals',
            name: '/settings/profile/approvals',
            component: () => import('/code/gunauction/gunauction-front/src/pages/settings/profile/approvals.vue'),
            /* no children */
            meta: {
              "requiresAdmin": true
            }
          },
          {
            path: 'bidding-summary',
            name: '/settings/profile/bidding-summary',
            component: () => import('/code/gunauction/gunauction-front/src/pages/settings/profile/bidding-summary.vue'),
            /* no children */
          },
          {
            path: 'dms',
            name: '/settings/profile/dms',
            component: () => import('/code/gunauction/gunauction-front/src/pages/settings/profile/dms.vue'),
            /* no children */
            meta: {
              "requiresAdmin": true
            }
          },
          {
            path: 'settings',
            name: '/settings/profile/settings',
            component: () => import('/code/gunauction/gunauction-front/src/pages/settings/profile/settings.vue'),
            /* no children */
          }
        ],
      }
    ],
    meta: {
      "requiresAuth": true
    }
  },
  {
    path: '/valuation',
    name: '/valuation',
    component: () => import('/code/gunauction/gunauction-front/src/pages/valuation.vue'),
    children: [
      {
        path: '',
        name: '/valuation/',
        component: () => import('/code/gunauction/gunauction-front/src/pages/valuation/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/valuation/[id]',
        component: () => import('/code/gunauction/gunauction-front/src/pages/valuation/[id].vue'),
        /* no children */
      },
      {
        path: 'gun',
        /* internal name: '/valuation/gun' */
        /* no component */
        children: [
          {
            path: ':id',
            name: '/valuation/gun/[id]',
            component: () => import('/code/gunauction/gunauction-front/src/pages/valuation/gun/[id].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'guns',
        name: '/valuation/guns',
        component: () => import('/code/gunauction/gunauction-front/src/pages/valuation/guns.vue'),
        /* no children */
      }
    ],
    meta: {
      "requiresAuth": false
    }
  }
]
